<template>
  <div>
    <b-row>
      <b-col cols="10">
        <h1>{{ $t("Códigos promocionales") }}</h1>
        <h5 class="text-primary">
          {{ $t("codPromPlatform") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card>
          <b-row class="align-items-center">
            <b-col>
              <b-form-input
                v-model="search"
                :placeholder="$t('Search')"
                @change="onKeyUp"
              />
            </b-col>
            <b-col md="1">
              <b-button
                variant="primary"
                @click="onKeyUp"
              >
                {{ $t('Search') }}
              </b-button>
            </b-col>
            <b-col md="2">
              <b-form-select
                v-model="selectType"
                :options="typesPromo"
                @change="onKeyUp"
              />
            </b-col>
            <b-col md="2">
              <b-form-select
                v-model="selectState"
                :options="typesState"
                @change="onKeyUp"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-table
                ref="selectableTable"
                :busy="show"
                striped
                hover
                :no-border-collapse="true"
                :items="codes"
                :fields="columns"
                :selectable="true"
                select-mode="single"
                responsive
                @row-selected="onRowSelected"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                    <strong>{{ $t('load') }}...</strong>
                  </div>
                </template>
                <template #cell(code)="data">
                  <div class="d-flex align-items-center">
                    {{ data.item.node.code }}
                  </div>
                </template>
                <template #cell(expirationDate)="data">
                  <div class="d-flex align-items-center">
                    {{ formatearFechaHora(data.item.node.expirationDate) }}
                  </div>
                </template>
                <template #cell(promotionType)="data">
                  <div class="d-flex align-items-center">
                    {{ typesPromo[data.item.node.promotionType] }}
                  </div>
                </template>
                <template #cell(allowedUses)="data">
                  <div class="d-flex align-items-center">
                    {{ data.item.node.allowedUses }}
                  </div>
                </template>
                <template #cell(isReusable)="data">
                  <div class="d-flex align-items-center">
                    <b-badge :variant="data.item.node.isReusable ? 'success' : 'danger'">
                      {{ data.item.node.isReusable ? $t('reusableOne') : $t('reusableNot') }}
                    </b-badge>
                  </div>
                </template>
                <template #cell(isActive)="data">
                  <div class="d-flex align-items-center">
                    <b-badge :variant="data.item.node.isActive ? 'success' : 'danger'">
                      {{ data.item.node.isActive ? $t('dataGeneric.active') :
                        $t('dataGeneric.inactive') }}
                    </b-badge>
                  </div>
                </template>
                <template #cell(createdAt)="data">
                  <div class="d-flex align-items-center">
                    {{ formatearFechaHora(data.item.node.createdAt) }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex align-items-center">
                    <b-dropdown
                      variant="link"
                      no-caret
                      toggle-class="p-0"
                      dropleft
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="17"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item @click.prevent="loadModal(data.item.node)">
                        <div class="d-flex">
                          <feather-icon
                            icon="Edit2Icon"
                            class=" mr-2"
                            size="18"
                          />
                          {{ $t('dataGeneric.edit') }}
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-pagination
                v-if="rows > perPage"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                size="md"
                @input="fetchData()"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="modal"
      centered
      size="lg"
      :title="$t('Códigos promocionales')"
      @hidden="resetModal"
      @ok="validationForm"
    >
      <validation-observer
        ref="promotionalCode"
        tag="form"
        @submit.stop.prevent="validationForm"
      >
        <b-overlay
          :show="show"
          :opacity="1.00"
          variant="transparent"

          :blur="'2px'"
          rounded="lg"
        >
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="name-input"
                rules="required"
              >
                <b-form-group
                  :label="$t('purchases.code') + '*'"
                  label-for="name-input"
                  :invalid-feedback="$t('required')"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-input
                    id="name-input"
                    v-model="code"
                    :placeholder="$t('firstName')"
                    :state="errors.length > 0 ? false : null"
                    maxlength="150"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('dataGeneric.description')"
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  class="mb-1"
                  :placeholder="$t('dataGeneric.description')"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('filterAllPromo')"
                label-for="promotionType"
              >
                <b-form-select
                  id="promotionType"
                  v-model="promotionType"
                  :options="typeOptions"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="promotionType == 'SUB'">
              <b-form-group>
                <b-form-input
                  v-model="subscriptionName"
                  :placeholder="$t('domain.sus')"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col v-if="promotionType == 'CAT'">
              <b-form-group>
                <b-form-tags
                  v-model="categories"
                  :placeholder="$t('purchases.category')"
                  :disabled="categories.length == 0"
                >
                  <template
                    #default="{
                      tags,
                      removeTag
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="JSON.parse(tag).id"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="JSON.parse(tag).name"
                          variant="primary"
                          @remove="removeTag(tag)"
                        >
                          {{ JSON.parse(tag).name }}
                        </b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-form-group>
            </b-col>
            <b-col v-if="promotionType == 'CON'">
              <b-form-group>
                <b-form-tags
                  v-model="contents"
                  :placeholder="$t('contents.content')"
                  :disabled="contents.length == 0"
                >
                  <template
                    #default="{
                      tags,
                      removeTag
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="JSON.parse(tag).id"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="JSON.parse(tag).name"
                          variant="primary"
                          @remove="removeTag(tag)"
                        >
                          {{ JSON.parse(tag).name }}
                        </b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-button
                variant="primary"
                @click="showModalType(promotionType)"
              >
                {{ $t('purchases.selectCategory') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="expirationDate">
                <label class="d-flex mb-50">
                  {{ $t('subscriptions.expiredAt') }}
                  <feather-icon
                    v-if="expirationDate"
                    class="text-danger cursor-pointer ml-2"
                    icon="XOctagonIcon"
                    size="15"
                    @click="expirationDate = null"
                  />
                </label>
                <flat-pickr
                  v-model="expirationDate"
                  class="form-control"
                  :placeholder="$t('subscriptions.expiredAt')"
                  :config="{
                    enableTime: true,
                    enableSeconds: true,
                    dateFormat: 'Y-m-d H:i:S',
                    minDate: today
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('allowedUsers')"
                label-for="allowedUsers"
              >
                <b-form-tags
                  v-model="allowedUsers"
                  :placeholder="$t('allowedUsers')"
                  :disabled="allowedUsers.length == 0"
                >
                  <template
                    #default="{
                      tags,
                      removeTag
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="JSON.parse(tag).id"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="cleanUsername(JSON.parse(tag).name)"
                          variant="primary"
                          @remove="removeTag(tag)"
                        >
                          {{ cleanUsername(JSON.parse(tag).name) }}
                        </b-form-tag>
                      </li>
                    </ul>
                  </template>
                </b-form-tags>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              class="mt-2"
            >
              <b-button
                variant="primary"
                @click="showModalType('USR')"
              >
                {{ $t('purchases.selectCategory') }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('allowedUses')"
                label-for="allowedUses"
              >
                <b-form-input
                  id="allowedUses"
                  v-model.number="allowedUses"
                  type="number"
                  :placeholder="$t('allowedUses')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col md="4">
              <b-form-group>
                <b-form-checkbox
                  v-model="isReusable"
                  class="mb-1"
                  switch
                >
                  {{ $t('reusableQ') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox
                  v-model="isActive"
                  class="mb-1"
                  switch
                >
                  {{ $t('dataGeneric.activeQ') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-overlay>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
        <b-overlay
          :show="show"
          variant="transparent"
          class="d-flex"
          :opacity="1.00"
          :blur="'2px'"
          rounded="lg"
        >
          <template #overlay>
            <div class="d-flex align-items-center">
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <b-spinner
                type="grow"
                variant="dark"
              />
              <b-spinner
                small
                type="grow"
                variant="secondary"
              />
              <span class="sr-only">{{ $t('wait') }}...</span>
            </div>
          </template>
          <b-button
            variant="danger"
            @click="cancel()"
          >
            {{ $t('dataGeneric.cancel') }}
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="ok()"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-overlay>
      </template>
    </b-modal>
    <b-modal
      ref="SuscriptionModal"
      size="xl"
      hide-footer
      :title="$t('common.el')"
    >
      <div class="d-block text-center">
        <suscription-selector-modal @data="SelectSubscriptionId" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.SuscriptionModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="CatModal"
      size="xl"
      hide-footer
      :title="$t('modal.category')"
    >
      <div class="d-block text-center">
        <category-selector
          :columns="[
            { key: 'image', label: $t('contents.category') },
            { key: 'name', label: $t('firstName') },
            { key: 'type', label: $t('type') },
          ]"
          @confirm-selection="getCategory"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.CatModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="ConModal"
      size="xl"
      hide-footer
      :title="$t('select')"
    >
      <div class="d-block text-center">
        <content-selector @info="getContent" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click.stop.prevent="$refs.ConModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      ref="userModal"
      size="xl"
      hide-footer
      :title="$t('fileNameUserRegistered')"
    >
      <div class="d-block ">
        <final-user-selector @finalUser="getFinalUser" />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs.userModal.hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BCard,
  BFormInput,
  BDropdownItem,
  BFormTags,
  BFormTag,
  BBadge,
  BPagination,
  BOverlay,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import axios from '@axios'
import {
  messageError, showToast, cleanUsername,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SuscriptionSelectorModal from '@/views/common/SuscriptionSelectorModal.vue'
import ContentSelector from '@/views/common/ContentSelector.vue'
import CategorySelector from '@/views/common/CategorySelector.vue'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import FinalUserSelector from '@/views/common/FinalUserSelector.vue'
import { required } from '@validations'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormTag,
    BBadge,
    BFormSelect,
    flatPickr,
    BFormGroup,
    FinalUserSelector,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    BButton,
    ContentSelector,
    BFormTags,
    CategorySelector,
    BCard,
    SuscriptionSelectorModal,
    BTable,
    BOverlay,
    BPagination,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      rows: 0,
      codes: [],
      show: false,
      selected: [],
      currentPage: 1,
      userData: getUserData(),
      search: '',
      selectType: 'ALL',
      selectState: null,
      typesPromo: {
        ALL: this.$t('filterAllPromo'),
        SUB: this.$t('domain.sus'),
        CAT: this.$t('purchases.category'),
        CON: this.$t('purchases.content'),
      },
      typesState: [
        { value: null, text: this.$t('subscriptions.status') },
        { value: true, text: this.$t('dataGeneric.active') },
        { value: false, text: this.$t('dataGeneric.inactive') },
      ],
      perPage: 10,
      columns: [
        { key: 'code', label: this.$t('purchases.code') },
        { key: 'createdAt', label: this.$t('subscriptions.createdAt') },
        { key: 'expirationDate', label: this.$t('subscriptions.expiredAt') },
        { key: 'promotionType', label: this.$t('promotionType') },
        { key: 'allowedUses', label: this.$t('allowedUses') },
        { key: 'isReusable', label: this.$t('reusable') },
        { key: 'isActive', label: this.$t('code.status') },
      ],
      isSuperuser: false,
      code: null,
      description: null,
      expirationDate: null,
      allowedUses: null,
      isReusable: false,
      promotionType: 'SUB',
      typeOptions: [
        { text: this.$t('domain.sus'), value: 'SUB' },
        { text: this.$t('purchases.category'), value: 'CAT' },
        { text: this.$t('contents.content'), value: 'CON' },
      ],
      headers: {},
      isActive: false,
      categories: [],
      contents: [],
      allowedUsers: [],
      subscriptionId: null,
      subscriptionName: null,
      today: new Date(),
      id: null,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.isSuperuser = this.userData.isSuperuser

    this.fetchData()
  },
  methods: {
    cleanUsername,
    loadModal(data) {
      this.show = true
      const {
        code, id, description, expirationDate, allowedUses, allowedUsers, isReusable, promotionType, isActive, categories, contents, subscription,
      } = data
      const allowedUsersEdges = allowedUsers.edges.map(({ node }) => ({ id: node.id, name: node.username }))
      const contentsEdges = contents.edges.map(({ node }) => ({ id: node.id, name: node.name }))
      const categoriesEdges = categories.edges.map(({ node }) => ({ id: node.id, name: node.name }))
      this.id = id
      this.code = code
      this.description = description
      this.expirationDate = expirationDate
      this.allowedUses = allowedUses
      this.isReusable = isReusable
      this.promotionType = promotionType
      this.isActive = isActive
      this.allowedUsers = allowedUsersEdges

      this.categories = categoriesEdges

      this.contents = contentsEdges

      this.subscriptionId = subscription?.id
      this.subscriptionName = subscription?.name

      this.$refs.modal.show()
      this.show = false
    },
    resetModal() {
      this.$refs.modal.hide()
      this.fetchData()

      this.id = null
      this.isReusable = false
      this.isActive = false
      this.code = null
      this.description = null
      this.expirationDate = null
      this.promotionType = 'SUB'

      this.allowedUsers = []
      this.categories = []
      this.contents = []

      this.subscriptionId = null
      this.subscriptionName = null
      this.allowedUses = null
      this.show = false
    },
    onKeyUp() {
      this.currentPage = 1
      this.fetchData()
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.promotionalCode.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.id === null) { this.createPromotionalCodes() } else this.updatePromotionalCodes()
          }
        })
      })
    },
    createPromotionalCodes() {
      this.show = true
      const { headers } = this
      const variables = {
        code: this.code,
        client: this.userData.profile.client.id,
        description: this.description,
        isActive: this.isActive,
        expirationDate: this.expirationDate ? new Date(this.expirationDate).toISOString() : null,
        allowedUses: this.allowedUses,
        isReusable: this.isReusable,
        promotionType: this.promotionType,
        subscription: this.subscriptionId,
        contents: this.contents.map(item => JSON.parse(item).id),
        categories: this.categories.map(item => JSON.parse(item).id),
        allowedUsers: this.allowedUsers.map(item => JSON.parse(item).id),
      }
      let qr = ''
      let mt = ''
      switch (this.promotionType) {
        case 'SUB':
          qr = '$subscription: ID'
          mt = 'subscription: $subscription'
          break
        case 'CAT':
          qr = '$categories: [ID]'
          mt = 'categories: $categories'
          break
        case 'CON':
          qr = '$contents: [ID]'
          mt = 'contents: $contents'
          break
        default:
          break
      }
      const query = `
      mutation(
        $client: ID!, $code:String!,$description:String,$isActive:Boolean,
        $isReusable:Boolean, $promotionType: ContentsPromotionalCodePromotionTypeChoices,
        $allowedUses: Int,${qr}, $expirationDate: DateTime,$allowedUsers: [ID]      
        ){
        createPromotionalcode(input:{              
              code:$code,
              client:$client,
              description:$description,
              isActive:$isActive,
              isReusable:$isReusable,
              promotionType:$promotionType,
              allowedUses:$allowedUses,
              expirationDate:$expirationDate,
              allowedUsers:$allowedUsers,
              ${mt}
              }) {
                promotionalCode{
                    id
                    code
                    expirationDate
                    description
                    allowedUses
                    isReusable
                    promotionType
                      subscription {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    isActive
                    usedBy {
                    edges {
                        node {
                          id
                          username
                        }
                    }
                    }
                    createdAt
                    promotionalcodeusesSet {
                    edges {
                        node {
                          id
                        }
                    }
                    }
                    allowedUsers {
                    edges {
                        node {
                          id
                          username
                        }
                    }
                    }                   
                }
              }
              
          }`
      axios
        .post('', { query, variables }, { headers })
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)

          this.resetModal()
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
    updatePromotionalCodes() {
      this.show = true

      const { headers } = this
      let qr = ''
      let mt = ''
      switch (this.promotionType) {
        case 'SUB':
          qr = '$subscription: ID'
          mt = 'subscription: $subscription'
          break
        case 'CAT':
          qr = '$categories: [ID]'
          mt = 'categories: $categories'
          break
        case 'CON':
          qr = '$contents: [ID]'
          mt = 'contents: $contents'
          break
        default:
          break
      }
      const query = `
      mutation(
        $id:ID!, $code:String!,$description:String,$isActive:Boolean,
        $isReusable:Boolean, $promotionType: ContentsPromotionalCodePromotionTypeChoices,
        $allowedUses: Int,${qr}, $expirationDate: DateTime,$allowedUsers: [ID]      
        ){
          updatePromotionalcode(id: $id,input:{              
              code:$code,
              description:$description,
              isActive:$isActive,
              isReusable:$isReusable,
              promotionType:$promotionType,
              allowedUses:$allowedUses,
              expirationDate:$expirationDate,
              allowedUsers:$allowedUsers,
              ${mt}
              }) {
                promotionalCode{
                    id
                    code
                    expirationDate
                    description
                    allowedUses
                    isReusable
                    promotionType
                      subscription {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    isActive
                    usedBy {
                    edges {
                        node {
                          id
                          username
                        }
                    }
                    }
                    createdAt
                    promotionalcodeusesSet {
                    edges {
                        node {
                          id
                        }
                    }
                    }
                    allowedUsers {
                    edges {
                        node {
                          id
                          username
                        }
                    }
                    }                   
                }
              }
          }`
      axios
        .post('', {
          variables: {
            code: this.code,
            id: this.id,
            description: this.description,
            isActive: this.isActive,
            expirationDate: this.expirationDate ? new Date(this.expirationDate).toISOString() : null,
            allowedUses: this.allowedUses,
            isReusable: this.isReusable,
            promotionType: this.promotionType,
            subscription: this.subscriptionId,
            contents: this.contents.map(item => JSON.parse(item).id),
            categories: this.categories.map(item => JSON.parse(item).id),
            allowedUsers: this.allowedUsers.map(item => JSON.parse(item).id),
          },
          query,
        }, { headers })
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)

          this.resetModal()
        })
        .catch(() => {
          this.show = false

          showToast(this.$t('error'), 2, this)
        })
    },
    getFinalUser(item) {
      const { id, username } = item
      this.allowedUsers.push({ id, name: username })
      this.$refs.userModal.hide()
    },
    showModalType(value) {
      switch (value) {
        case 'SUB':
          this.$refs.SuscriptionModal.show()
          break
        case 'CAT':
          this.$refs.CatModal.show()
          break
        case 'CON':
          this.$refs.ConModal.show()
          break
        default:
          this.$refs.userModal.show()
          break
      }
    },
    formatearFechaHora(fechaISO) {
      if (fechaISO) {
        const fecha = new Date(fechaISO)
        const dia = fecha.getDate().toString().padStart(2, '0')
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
        const anio = fecha.getFullYear()
        const hora = fecha.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })

        return `${dia}/${mes}/${anio} ${hora}`
      }
      return '--/--/---- --:--'
    },
    getContent(item) {
      const id = item[0]
      const tag = item[1]
      this.contents.push({ id, name: tag })
      this.$refs.ConModal.hide()
    },
    SelectSubscriptionId(data) {
      const [suscriptionId, suscriptionName] = data
      this.suscriptionId = suscriptionId
      this.suscriptionName = suscriptionName
      this.$refs.SuscriptionModal.hide()
    },
    getCategory(item) {
      const { id, name } = item.node
      this.categories.push({ id, name })
      this.$refs.CatModal.hide()
    },
    onRowSelected(items) {
      try {
        this.selected = items[0].node
        this.$emit('promotionalCode', this.selected)
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    },
    fetchData() {
      const { headers } = this
      const query = `{
        allPromotionalcode(
              code:"${this.search}",
              client:"${this.userData.profile.client.id}",
              first:${this.perPage},
              ${this.selectType === 'ALL' ? '' : `promotionType:${this.selectType},`}
              ${this.selectState !== null ? `isActive:${this.selectState},` : ''}
              orderBy:"-created_at",
              offset:${this.perPage * (this.currentPage - 1)},) {
                totalCount
                edges {
                    node {
                    id
                    code
                      expirationDate
                      description
                    allowedUses
                    isReusable
                    promotionType
                      subscription {
                        id
                        name
                      }
                      categories {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                      contents {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    isActive
                    usedBy {
                    edges {
                        node {
                          id
                          username
                        }
                    }
                    }
                    createdAt
                    promotionalcodeusesSet {
                    edges {
                        node {
                          id
                        }
                    }
                    }
                    allowedUsers {
                    edges {
                        node {
                          id
                          username
                        }
                    }
                    }                   
                }
              }
            }
          }`

      axios
        .post('', { query }, { headers })
        .then(result => {
          messageError(result, this)
          this.codes = result.data.data.allPromotionalcode.edges
          this.rows = result.data.data.allPromotionalcode.totalCount
        })
        .catch(() => {

        })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
